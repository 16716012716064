nav {
    background-color: #001f3f;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

nav img {
    height: 40px;
}

nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

nav ul li {
    margin: 0 15px;
    position: relative; /* Asegura que el submenú esté alineado correctamente */
}

nav ul li a, nav ul li span {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    display: block; /* Asegura que el enlace y el span tengan el mismo tamaño */
    padding: 10px 0; /* Alinea verticalmente con el resto del menú */
}

nav .seplogo {
    height: 50px;
    margin-right: 20px;
}

.logo {
    width: 100px;
    height: auto;
}

.hamburger {
    display: none;
    font-size: 30px;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
}

@media (max-width: 768px) {
    .hamburger {
        display: block;
    }

    .nav-links {
        display: none;
        flex-direction: column;
        width: 100%;
        text-align: center;
    }

    .nav-links.open {
        display: flex;
    }

    nav ul {
        flex-direction: column;
    }

    nav ul li {
        margin: 10px 0;
    }

    .submenu .dropdown {
        position: static;
    }

    .submenu .dropdown.open {
        display: flex;
        flex-direction: column;
    }
}

.nav-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
}

.nav-links li {
    margin: 0 15px;
}

.nav-links a {
    text-decoration: none;
    color: #fff;
}

.submenu {
    position: relative;
}

.submenu .dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 10px;
    margin: 0;
}

.submenu:hover .dropdown {
    display: block;
}

.dropdown li {
    margin: 0;
}

.dropdown a {
    padding: 10px;
    display: block;
    color: #333;
}