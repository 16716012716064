.efemerides-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
}

.efemerides-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.efemerides-content {
    line-height: 1.6;
    color: #555;
}

.efemerides-content h2 {
    color: #007BFF;
    margin-top: 20px;
}

.efemerides-content ul {
    list-style-type: none;
    padding: 0;
}

.efemerides-content ul li {
    background: #fff;
    margin: 5px 0;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
