
.page-container {
    background-color: #f0f0f0;
}

.login-box {
    padding: 1rem;
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.login-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-align: center;
}

.form-label {
    font-size: 0.875rem;
}

.message {
    font-size: 0.875rem;
    text-align: center;
    margin-top: 1rem;
}