
.preinscripciones-container {
    background-color: #f0f0f0;
    color: #333;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.preinscripciones-container h1 {
    color: #007BFF;
}

.preinscripciones-container h2 {
    color: #007BFF;
}

.preinscripciones-container ul {
    list-style-type: disc;
    padding-left: 20px;
}

.preinscripciones-container ol {
    list-style-type: decimal;
    padding-left: 20px;
}

.preinscripciones-container a {
    color: #007BFF;
    text-decoration: none;
}

.preinscripciones-container a:hover {
    text-decoration: underline;
}