body {
  background-color: #f0f0f0; /* Fondo gris claro */
  color: #333;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #001f3f; /* Azul marino */
  padding: 10px 20px;
}

.navbar img {
  height: 40px; /* Ajusta el tamaño del logotipo */
}

.navbar-menu {
  display: flex;
  gap: 15px;
}

.navbar-menu a {
  color: white; /* Texto blanco */
  text-decoration: none;
  font-weight: bold;
}

.navbar-menu a:hover {
  text-decoration: underline; /* Subrayado al pasar el ratón */
}

.footer {
  background-color: #001f3f; /* Azul marino */
  color: white; /* Texto blanco */
  text-align: center;
  padding: 20px 0;
}