.cookie-consent-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: slideUp 0.5s ease-in-out;
    z-index: 1000; /* Asegúrate de que la barra esté por encima de otros elementos */
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.cookie-consent-bar button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 1rem;
}

.cookie-consent-bar button:hover {
    background-color: #45a049;
}
