.conocenos-container {
    background-color: #d3d3d3;
    color: #001f3f;
    padding: 20px;
}

.conocenos-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
}

.conocenos-container p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 15px;
}
