
.encuesta-container {
  background-color: #d3d3d3;
  padding: 20px;
}

.encuesta-title {
  color: #003366;
}

.encuesta-description {
  color: #333;
}

.encuesta-question label {
  color: #003366;
}

.encuesta-submit {
  background-color: #003366;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
}