html {
    font-size: 80%;
}

* {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
}

body {
    color: #404041;
    background-color: #ffffff; /* Fondo blanco */
}

.titulo_blanco {
    color: white;
    font-size: 2.2em;
    font-weight: normal;
}

.texto_blanco {
    color: white;
    font-size: .5em;
    font-weight: normal;
}

.img-responsive {
    max-width: 100%;
    height: auto;
}

.header-mg {
    padding: 3.5em 0;
}

.calendario-main-container {
    background-color: #ffffff; /* Fondo blanco */
    margin: 0;
    padding: 3em 0;
}

.month {
    width: calc(2em * 7);
    padding: 1em;
    cursor: pointer;
}

.month h4 {
    margin-bottom: 0;
    font-size: 1em;
    text-transform: uppercase;
    background: #285c4e;
    color: #fff;
    padding: .3em 0;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}
