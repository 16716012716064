.news-section {
    background-color: #f0f0f0;
    padding: 20px;
}

.news-section h2 {
    color: #003366;
}

.news-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.news-card {
    flex: 1 1 calc(33.333% - 20px);
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.news-card h3 {
    color: #003366;
}

.news-card p {
    margin-bottom: 20px;
}

.news-card button {
    background-color: #007BFF;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
}

.news-card button:hover {
    background-color: #0056b3;
}

.news-card-buttons {
    display: flex;
    flex-wrap: wrap;
}

.facebook-feed {
    margin-top: 20px;
}

.facebook-feed iframe {
    border: none;
    overflow: hidden;
}

.news-card-important {
    background-color: #007BFF;
    color: #fff;
    animation: glow 1s infinite alternate;
}

.news-card-important button {
    background-color: #fff;
    color: #007BFF;
}

.news-card-important button:hover {
    background-color: #f0f0f0;
}

@keyframes glow {
    from {
        box-shadow: 0 0 10px #007BFF;
    }
    to {
        box-shadow: 0 0 20px #007BFF;
    }
}
