.construction-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.construction-content {
  text-align: center;
  background: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.construction-title {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
}

.construction-text {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 30px;
  font-family: 'Arial', sans-serif;
}

.construction-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.construction-message {
  font-size: 1.5em;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.construction-subtext {
  font-size: 1.2em;
  color: #666;
  font-family: 'Arial', sans-serif;
}
