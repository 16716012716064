footer {
  background-color: #001f3f;
  color: #ffffff;
  padding: 20px;
  text-align: center;
}

footer p {
  margin: 5px 0;
}

.facebook-link {
  color: #ffffff;
  font-size: 1.5rem;
  margin-top: 10px;
  display: inline-block;
}

.footer {
  text-align: center;
  padding: 10px;
  background-color: #001f3f;
  color: #fff;
}

.footer-link {
  color: #fff;
  text-decoration: none;
}